<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">畅游福井必备的景点套票，精选福井多个人气设施与美食店家，一週内可以任选三个喜欢的设施，出示电子票券QR code就可以轻松入场体验。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●同设施只可以使用一次，相同设施不可再入场<br>
          ●票劵使用效期为购买日起<span>90天内</span><br>
           EX：<span>7/1</span>购买票券⇒<span>票期:7/1~9/28</span><br>
          ●票券有效期限: 使用第一个设施后，7日内须使用完毕<br>
          EX：<span>7/1</span>开始使用，<span>有效期限至7/7</span><br>
          ●有效期间内可任<span>选3项设施</span><br>
          ●注意: 单次购买複数票劵时，当其中一张票劵开始使用，所有票劵也将同步开通 (<span>使用效期7天</span>)<br>
          ●票券开通后，7天的使用效期无法超过票券<span>90天</span>的有效期间。<br>
           EX：票券期限<span>7/1~9/28</span>，若<span>9/27</span>开始使用，其使用效期为<span>9/27~9/28</span><br>
          ●无使用完毕不能兑现<br>
          ●请事先于以下连结确认各设施注意事项・营业时间・公休日情报
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事项'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='兑换地点' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]" target="_blank" :href="cItem" v-show="msg.title==='官方网站' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事项特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hffukui/zh/havefun_title_sc.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hffukui/01_SC.jpg'),
          title: ['越前铁道全线1日无限乘车券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '福井县福井市中央1丁目1-1(越前铁道福井站柜台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.echizen-tetudo.co.jp/'] },
                { cid: 2, text: ['可使用路线图:'],noColor:true },
                { cid: 3, text: ['https://www.echizen-tetudo.co.jp/route/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['产品内容: 一日内可无限次搭乘越前铁道的三国芦原线・胜山永平寺线。','<br>※不适用：路线巴士・福井铁道'] },
                { cid: 2, text: ['使用前请务必先去兑换成全线1日无限乘车券。'] },
            
                ]
            }
          ]
        },
        // 
        {
          id: 2,
          img: require('@/assets/images/hffukui/02_SC.jpg'),
          title: ['福井站前5店铺使用可能!荞麦麵美食巡礼♪荞麦麵周游优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '福井县福井市中央1-2-1 (Happiring 1F综合柜台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.happiring.com/event/detail.php?cd=1409'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可使用店铺: 荞麦店 丸八、越前荞麦店 见吉屋本店、网代荞麦店 福之井店、福井市观光物产福福馆 福福茶屋、越前荞麦倶乐部 '] },
                { cid: 2, text: ['营业时间请事先至官网确认。','<br>※可能因临时休业・客满而无法使用。'] },
                { cid: 3, text: ['请至Happiring 1F综合柜台出示二维码兑换优惠券。'] },
                { cid: 4, text: ['前往选定的店铺于点餐时出示优惠券。我们将从您的总帐单中抵扣相应张数的优惠券。','<br>※适用餐点因店铺而异。','<br>※每家店铺可使用的优惠券数量不同。'] },
                { cid: 5, text: ['一张优惠券含4张面值为300日圆的优惠券。'] },
                { cid: 6, text: ['优惠券(实体券)兑换后半年内有效。'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hffukui/03_SC.jpg'),
          title: ['JR福井站前複合大楼「Happiring」馆内通用优惠券1000日圆'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '福井县福井市中央1-2-1 (Happiring 1F综合柜台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.happiring.com/zh/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日圆优惠券。'] },    
                { cid: 2, text: ['结帐时请在各店铺出示优惠券。(出示二维码无法使用。请务必在使用前兑换成优惠券。)'] },    
                { cid: 3, text: ['本券不可兑换现金或找零。'] },    
                { cid: 4, text: ['无法使用于对象店舖之外的地方。(馆内不适用优惠券店铺：彩券、Family Mart、Seiren Planet)'] },    
                { cid: 5, text: ['优惠券(实体券)兑换后半年内有效。'] },    
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hffukui/04_SC.jpg'),
          title: ['丸冈城入场券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '福井县坂井市丸冈町1-59 (丸冈城售票处)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://enjoy.pref.fukui.lg.jp/scn/spot/spot-20/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['商品内容: 包含「丸冈城」、「丸冈歴史民俗资料馆」、「一笔启上 日本最短书信馆」入场费用'] },
                { cid: 2, text: ['营业时间请事先至官网确认。 '] },
                { cid: 3, text: ['无法重複入场。'] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hffukui/05_SC.jpg'),
          title: ['芦原温泉「美松」一日泡汤 (含租借毛巾)'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '福井县芦原市舟津26-10 (1楼柜台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.mimatu.net/han/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['商品内容: 温泉入汤费、浴巾租借费'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['仅能入场泡汤一次。太阳殿是男澡堂，明月殿是女澡堂。'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hffukui/06_SC.jpg'),
          title: ['越前松岛水族馆入馆券 1500日圆优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '福井县坂井市三国町崎74-2-3(售票口)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.echizen-aquarium.com/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1500日圆优惠券。(仅能折抵入馆费)','<br>※不会找零'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['由于颱风、大雪、灾害等原因会有临时休馆、提前闭馆时间、延迟开馆时间的情况，敬请谅解。'] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hffukui/07_SC.jpg'),
          title: ['恐龙博物馆入馆券(常设展门票)'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '福井县胜山市村冈町寺尾51-11 (本馆综合柜台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.dinosaur.pref.fukui.jp/cn/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间请事先至官网确认。'] },
                { cid: 2, text: ['可能因展品更换或设施检查而临时休馆。'] },
                { cid: 3, text: ['可在票券(入馆券)记载的日期内重複入馆。'] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFFukui&l=sc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-fukui-1-week-free-pass/sc', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>